import React from 'react'
import styled from 'styled-components'
import { FaPaintBrush, FaBrain, FaServer } from 'react-icons/fa'
import colors from '../../style/color'
import { screen } from './screen'

const AboutMeContainer = styled.div`
  width: 100%;
  margin-top: 200px;
  display: flex;
  justify-content: center;
  margin-bottom: 200px;
`

const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 80%;
  max-width: 1400px;
`

const AbstractContainer  = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media screen and (max-width: ${screen.mobile}) {
    flex-direction: column;
  }
`

const RightAbstractContainer = styled.div`
  width: 50%;
  
  @media screen and (max-width: ${screen.mobile}) {
    width: 100%;
  }
`

const LeftAbstractContainer = styled.div`
  width: 40%;
  font-size: 60px;
  font-weight: 600;
  line-height: 1.4;
  margin-top: 20px;
  margin-bottom: 30px;

  @media screen and (max-width: ${screen.mobile}) {
    width: 100%;
  }
`

const SkillContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 60px;
  margin-right: -80px;

  @media screen and (max-width: ${screen.mobile}) {
    flex-direction: column;
    margin-right: 0;
  }
`

const SkillItem = styled.div`
  width: 600px;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 80px;
  border-radius: 20px;
  box-shadow: 3px 5px 10px rgba(0,0,0,0.25);
  padding: 30px;
  text-align: center;

  @media screen and (max-width: ${screen.mobile}) {
    width: 100%;
    margin-right: 0;

    &:not(:last-child) {
      margin-bottom: 80px;
    }
  }

  & > div {
    line-height: 1;
    font-size: 60px;
    color: ${colors.red[400]};
  }

  & > h3 {
    font-size: 32px;
    font-weight: 500;
    line-height: 2;
    margin-top: 20px;
  }
`


const AboutMe = () => {
  return (
    <AboutMeContainer id="about-section">
      <InnerContainer>
        <AbstractContainer>
          <LeftAbstractContainer>
            Allow me to tell you about Myself!
          </LeftAbstractContainer>
          <RightAbstractContainer>
            Hey there, my name is <b>Alvin</b>. I program stuff. Born in Indonesia, but there are many places I can call home. Now a Student at Kyoto Computer Collage.
            When I'm not busy, you can find me watching a movie or just in front of computer programming stuff. 
            Whether it's business or private, Please feel welcome to contact me. Understand English, Japanese and Indonesian
            <br /><br />

            alvinendratno@live.com
          </RightAbstractContainer>
        </AbstractContainer>

        <SkillContainer>
          <SkillItem>
            <div><FaBrain /></div>
            <h3>Macchine Learning</h3>
            <p>
              Learned Machine Learning in Kyoto Institute of Information Science. I also did a small project at the internship.
            </p>
          </SkillItem>
          <SkillItem>
            <div><FaPaintBrush /></div>
            <h3>Frontend</h3>
            <p>
              Started my first HTML at age 13 and still writing until now. As for Framework I often use <b>React</b>.  
            </p>
          </SkillItem>
          <SkillItem>
            <div><FaServer /></div>
            <h3>Backend</h3>
            <p>
              Certified in <b>AWS</b>. Have experience in <b>Ruby on Rails</b>, <b>Node.js</b>, <b>Flask</b>, etc
            </p>
          </SkillItem>
        </SkillContainer>
      </InnerContainer>
      {/* <h1 className="text-center text-4xl font-bold text-red-500">
        
      </h1>
      <p className="text-gray-600 mt-40 w-3/5 mx-auto">
        
        <h2 className="text-2xl text-red-400 my-20 font-semibold">Weapon</h2>
        I'm always learning web technologies and other design-related topics, currently studying Computer Networking. I also focus on many areas of design and web development. As the primary weapon,
        I'm using <b>React</b> as my front-end framework and Photoshop as a graphic and web design tool.
        <h2 className="text-2xl text-red-400 my-20 font-semibold">Me Designing</h2>
        Unlike many designers, my design doesn't start with a pencil. I always start by grabbing my mouse and lookups for inspiration from the internet. From there,
        Ideas begin to pop up in my head. Then add the imagery, typography design, and details.
        <h2 className="text-2xl text-red-400 my-20 font-semibold">Me Progamming</h2>
        For development, I write many things. Starting from web development to machine. For those interested, the text editor I use is VS Studio Code as IDE. I learning mainly
        javascript for front-end dev (mainly React) and Ruby for back-end dev (of course Ruby on Rails) These are the basic tools I use on my job and are my daily set up.
        <h2 className="text-2xl text-red-400 my-20 font-semibold">Me Writing</h2>
        This is a new and hobby kind of thing, I want to write blogs about my life, technology, and many more, and this website is my starting point.
        I am going to try updating constantly. 
      </p> */}
    </AboutMeContainer>
  )
}

export default AboutMe
