import * as React from 'react'
import styled from 'styled-components'
import { screen } from './screen'
import { FcMenu } from 'react-icons/fc'

const TopbarContainer = styled.header`
  width: 100vw;  
  display: flex;
  justify-content: center;
  transition: all 0.2s ease-in-out;
  background-color: #FFF;
  padding: 20px 0;

  & .white-logo {
    display: none;
  }

  & .black-logo {
    display: inline;
  }

  @media screen and (max-width: ${screen.mobile}) {
    & {
      background-color: #FFF;

      & .white-logo {
        display: none;
      }

      & .black-logo {
        display: inline;
      }

      @keyframes scale-in-ver-top {
        0% {
          transform: scaleY(0);
          transform-origin: 100% 0%;
          opacity: 1;
        }
        100% {
          transform: scaleY(1);
          transform-origin: 100% 0%;
          opacity: 1;
        }
      }
    }
  }
`

const InnerContainer = styled.div`
  width: 80%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const LogoContainer = styled.div`
  font-weight: 900;
`

const MenuContainer = styled.ul`
  display: flex;
  cursor: pointer;

  & > a {
    margin: 0 15px
  }

  @media screen and (max-width: ${screen.mobile}) {
    display: ${({ isShowing }) => isShowing ? 'flex' : 'none'};
    position: fixed;
    top: 83px;
    left: 0;
    flex-direction: column;
    align-items: center;
    width: 100vw;
    background-color: rgba(0, 0, 0, 0.9);
    color: #FFF;
    height: 100vh;
    animation: scale-in-ver-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;

    & > a {
      padding: 60px 0;
      border-top: 1px solid #FFF;
      width: 80vw;
      text-align: center;
    }
  }
`

const HumburgerContainer = styled.div`
  font-size: 40px;
  color: #000;

  @media screen and (min-width: ${screen.mobile}) {
    display: none;
  }
`

export const Topbar = () => {
  const [isShowing, setIsShowing] = React.useState(false)

  return (
    <TopbarContainer>
      <InnerContainer>
        <LogoContainer>
          alvinend
        </LogoContainer>
        <HumburgerContainer>
          <FcMenu onClick={() => setIsShowing(!isShowing)} />
        </HumburgerContainer>
        <MenuContainer isShowing={isShowing}>
          <a onClick={() => setIsShowing(!isShowing)} href="#home-section">Home</a>
          <a onClick={() => setIsShowing(!isShowing)} href="#about-section">About</a>
          <a onClick={() => setIsShowing(!isShowing)} href="#projects-section">Projects</a>
          <a onClick={() => setIsShowing(!isShowing)} href="#background-section">Background</a>
          <a onClick={() => setIsShowing(!isShowing)} href="#blog-section">Blog</a>
        </MenuContainer>
      </InnerContainer>
    </TopbarContainer>
  )
}
