import React from 'react'
import SectionHeader from '../SectionHeader'
import { UlStyled } from '../../UIStyled'
import SkillList from './SkillList'
import styled from 'styled-components'
import colors from '../../style/color'

const OuterContainerExperience = styled.div`
  margin-top: 200px;
  width: 100vw;
`

const Title = styled.h2`
  font-weight: bold;
  font-size: 30px;
  margin-bottom: 10px;

  @media only screen and (max-width: 1024px) {
    text-align: center;
  }
`

const PersonalityContainer = styled.div`
  text-align: left;
  width: 430px;

  &a {
    color: ${colors.red[500]}
  }

  & h3 {
    font-size: 26px;
    font-weight: 900;
    color: ${colors.red[500]}
  }

  & b{
    font-size: 20px;
    margin: 0 5px;
  }
`

const OuterWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  margin-top: 40px;
`

const InnerWrapper = styled.div`
  display: flex;
  width: 80%;
  flex-wrap: wrap;
  justify-content: space-between;

  @media only screen and (max-width: 1024px) {
    justify-content: center;
  }
`

const ItemWrapper = styled.div`
  width: 45%;
  margin-bottom: 30px;

  @media only screen and (max-width: 1024px) {
    width: 100%;
  }
`

const Experience = () => {

  return (
    <OuterContainerExperience id="background-section">
      <SectionHeader>Background</SectionHeader>
      <OuterWrapper>
        <InnerWrapper>
          <ItemWrapper>
            <Title>Education</Title>
            <UlStyled>
              <li>
                <b>Japanese</b> | 2016.08 - 2018.03
                <br />
                OBKG
                <br />
              </li>
              <li>
                <b>Computer Science/ Network</b> | 2018.04 - 2021.03 <br />
                Kyoto Computer Collage
                <br />
              </li>
              <li>
                <b>Master/ Web Business Major, Department of Applied Information Technology</b> | 2021.04 - Now <br />
                Kyoto Institute of Information Science
                <br />
              </li>
            </UlStyled>
          </ItemWrapper>
          <ItemWrapper>
            <Title>Experience & Certification</Title>
            <UlStyled>
              <li>
                <b>IPA Certified - Basic Engineer Test</b> | 2019 <br />
                IPA
                <br />
              </li>

              <li>
                <b>Intern Web Engineer Full Stack</b> | 2019.11 - Now <br />
                Baseconnect, Inc.
                <br />
              </li>

              <li>
                <b>AWS Certified Cloud Practitioner</b> | 2020 <br />
                Amazon Web Service
                <br />
              </li>

              <li>
                <b>AWS Certified Solutions Architect Associate</b> | 2021 <br />
                Amazon Web Service
                <br />
              </li>

              <li>
                <b>AWS Certified Developer Associate</b> | 2021 <br />
                Amazon Web Service
                <br />
              </li>

              <li>
                <b>AWS Certified Sysops Administrator Associate</b> | 2021 <br />
                Amazon Web Service
                <br />
              </li>

              <li>
                <b>Winner - 2021 Trigger Hackthon</b> | 2019.11 (10 days) <br />
                Sansan, Inc.
                <br />
              </li>

              <li>
                <b>Participant - Aiming For Commercialization Theme</b> | 2021.10 <br />
                GEEK TEN 2021
                <br />
              </li>
            </UlStyled>
          </ItemWrapper>
          <ItemWrapper>
            <Title>Skill</Title>
            <SkillList
              name="Javascript"
              desc="Love Javascript!"
              point={5}
            />
            <SkillList name="AWS" desc="Have 5 Certificates" point={5} />
            <SkillList name="React" desc="Use it almost everyday" point={5} />
            <SkillList name="CSS" desc="Frontend stuff" point={4} />
            <SkillList name="Node.Js/Express" desc="Backend stuff" point={3} />
            <SkillList name="Ruby(RoR)" desc="Used in work" point={3} />
            <SkillList name="Java" desc="JavaFX, Tomcat" point={2} />
            <SkillList name="Swift" desc="Used for fun (Not anymore)" point={2}/>
          </ItemWrapper>
          <ItemWrapper>
            <Title>Personality</Title>
            <PersonalityContainer>
              (Tested by <a href="https://www.16personalities.com/istp-personality">16personalities</a>)<br/>
              <h3><a href="https://www.16personalities.com/istp-personality">“The Virtuoso” (ISTP-T)</a></h3><br/>
              60%<b>Introverted</b>
              58%<b>Observant </b>
              56%<b>Thinking</b>
              51%<b>Prospecting </b>
              69%<b>Turbulent</b><br/><br/>
              Role<b>Explorer</b><br/>
              Strategy 	<b>Constant Improvement</b>
            </PersonalityContainer>
          </ItemWrapper>
        </InnerWrapper>
      </OuterWrapper>
    </OuterContainerExperience>
  )
}

export default Experience
