import React from 'react'
import styled from 'styled-components'
import colors from '../../style/color'
import {
  AiOutlineTwitter,
  AiFillInstagram,
  AiOutlineGithub,
  AiFillLinkedin
} from 'react-icons/ai'
import { SiHashnode } from 'react-icons/si'
import { screen } from './screen'


const OuterContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 200px;
  width: 100vw;
  height: 150px ;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${colors.red[200]};

  @media screen and (max-width: ${screen.mobile}) {
  }
`

const InnerContainer = styled.div`
  display: flex;
  width: 80%;
  
  @media screen and (max-width: ${screen.mobile}) {
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`

const SocialMediaContainer = styled.div`
  display: flex;
  width: 50%;

  & a {
    font-size: 30px;
    opacity: 0.8;

    &:hover {
      opacity: 1;
    }

    &:not(:last-child) {
      margin-right: 16px;
    }
  }
`

const MenuContainer = styled.ul`
  display: flex;
  justify-content: flex-end;
  width: 50%;
  cursor: pointer;

  & > a {
    margin: 0 15px
  }

  @media screen and (max-width: ${screen.mobile}) {
    margin-top: 20px;
    justify-content: center;
  }  
`

export const Footer = () => {
  return (
    <OuterContainer id="blog-section">
      <InnerContainer>
        <SocialMediaContainer>
          <a
            href="https://twitter.com/EndratnoAlvin"
            target="_blank"
            rel="noreferrer"
          >
            <AiOutlineTwitter />
          </a>
          <a
            href="https://www.instagram.com/alvinendratno/"
            target="_blank"
            rel="noreferrer"
          >
            <AiFillInstagram />
          </a>
          <a
            href="https://github.com/alvinend"
            target="_blank"
            rel="noreferrer"
          >
            <AiOutlineGithub />
          </a>
          <a
            href="https://hashnode.com/@alvinend"
            target="_blank"
            rel="noreferrer"
          >
            <SiHashnode />
          </a>
          <a
            href="https://www.linkedin.com/in/alvin-endratno-b58b70200/"
            target="_blank"
            rel="noreferrer"
          >
            <AiFillLinkedin />
          </a>
        </SocialMediaContainer>
        <MenuContainer>
          <a href="#home-section">Home</a>
          <a href="#about-section">About</a>
          <a href="#projects-section">Projects</a>
          <a href="#background-section">Background</a>
          <a href="#blog-section">Blog</a>
        </MenuContainer>
      </InnerContainer>
    </OuterContainer>
  )
}

