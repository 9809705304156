import React from 'react'
import SectionHeader from '../SectionHeader'
import PreviewCompatibleImage from '../../PreviewCompatibleImage'
import styled from 'styled-components'
import colors from '../../style/color'
import { FaCalendar } from 'react-icons/fa'
import axios from 'axios'
import dayjs from 'dayjs'

const OuterContainer = styled.div`
  margin-top: 200px;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 200px;
`

const Article = styled.article`
  position: relative;
  display: inline-block;
  flex-shrink: 0;
  width: 35%;
  margin-right: 50px;
  margin-bottom: 30px;
  box-shadow: 3px 5px 10px rgba(0,0,0,0.25);
  padding: 20px;
  padding-bottom: 70px;
  border-radius: 15px;

  @media only screen and (max-width: 1024px) {
    width: 100%;
    margin-right: 20px;
    margin-bottom: 40px;
  }
`

const ContentContainer = styled.div`
  display: flex;
  padding: 0 20px;
  margin-top: 40px;
  max-width: 1400px;
  width: 100%;
  overflow: scroll;

  @media only screen and (max-width: 1024px) {
    width: 90%;
  }
`

const Title = styled.p`
  font-size: 36px;
  line-height: 1.2;
  font-weight: 900;
  margin-top: 10px;
  margin-bottom: 10px;
`

const Desc = styled.p`
  font-size: 12px;
`

const ButtonMore = styled.a`
  display: inline-block;
  text-align: center;
  margin-top: 1rem;
  padding: 0.7rem 2rem;
  border-radius: 25px;
  border: 1px solid ${colors.red[600]};
  transition: all 0.25s;
  font-weight: 700;
  color: ${colors.gray[800]};

  &:hover {
    border: 1px solid ${colors.red[100]};
    background-color: ${colors.red[500]};
    color: ${colors.white}; 
  }
`

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

const CalendarIconContainer = styled.span`
  font-size: 24px;
  line-height: 1;
  color: ${colors.red[500]};
  display: inline-block;
`

const TextDate = styled.span`
  font-size: 16px;
  display: inline-block;
  margin-left: 10px;
  font-weight: 500;
`

const CalendarInfoContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 20px;
`

const ReadMoreText = styled.a`
  position: absolute;
  bottom: 30px;
  left: 20px;
  color: ${colors.red[500]};
`

const Dot = styled.div`
  border: 1px solid ${colors.red[500]};
  width: 15px;
  height: 15px;
  border-radius: 50%;

  ${({ active }) => active && `background-color: ${colors.red[500]};`}

  &:not(:last-child) {
    margin-right: 10px;
  }
`

const DotContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px;
`

const GET_USER_ARTICLES = `
    query GetUserArticles($page: Int!) {
        user(username: "alvinend") {
            publication {
                posts(page: $page) {
                    title
                    brief
                    slug
                    coverImage
                    dateAdded
                }
            }
        }
    }
`;

const BlogList = () => {
  const [posts, setPosts] = React.useState([])

  const getHashNodeBlogs = async () => {
    const data = await axios.post(
      'https://api.hashnode.com/',
      {
        query: GET_USER_ARTICLES,
        variables: {
          page: 0
        }
      },
      {
        headers: {
          'Content-Type': 'application/json',
        }
      }
    )

    setPosts(data.data.data.user.publication.posts)
  }

  React.useEffect(
    () => {
      getHashNodeBlogs()
    },
    []
  )

  const target = React.createRef()
  const [scrollProgress, setScrollProgress] = React.useState(0)

  const scrollListener = () => {
    if (!target.current) {
      return;
    }
  
    const element = target.current;
    const windowScroll = element.scrollLeft; // Distance of the scrollbar from the leftmost point
    const totalWidth = element.scrollWidth - element.clientWidth; // Total width the scrollbar can traverse
    if (windowScroll === 0) {
      return setScrollProgress(0);
    }
  
    if (windowScroll > totalWidth) {
      return setScrollProgress(100);
    }
  
    setScrollProgress((windowScroll / totalWidth) * 100);
  }

  React.useEffect(() => {
    target.current.addEventListener('scroll', scrollListener);
  })

  const renderDots = () => {
    const selectedDotValue = (scrollProgress * posts.length) / 100;
    
    const handleClick = (index) => () => {
      if (!target.current) {
        return;
      }
    
      const element = target.current;
      const totalWidth = element.scrollWidth - element.clientWidth; // Total width the scrollbar can traverse
      element.scrollTo(((totalWidth/posts.length) * index) + 1, 0)
    }
    
    return [...Array(posts.length).keys() ].map(index => (
      <Dot
        key={index}
        active={selectedDotValue >= index && selectedDotValue <= index + 1}
        onClick={handleClick(index)}
      />
    ));
  }

  return (
    <OuterContainer id="blog-section">
      <SectionHeader>Latest Blog</SectionHeader>
      <p className="text-gray-600 text-center">
        Want to know more? Feel free to read my blog! (External Link to Hashnode) <br />
      </p>
      <ContentContainer ref={target}>
        {!!posts.length &&
          posts.map(post => (
              <Article key={post.id}>
                <header>
                  {post.coverImage ? (
                      <div>
                        <PreviewCompatibleImage
                          imageInfo={{
                            image: post.coverImage,
                            alt: `featured image thumbnail for post ${post.title}`,
                          }}
                        />
                      </div>
                  ) : null}
                </header>
                <CalendarInfoContainer>
                  <CalendarIconContainer>
                    <FaCalendar />
                  </CalendarIconContainer>
                  <TextDate>
                    {dayjs(post.dateAdded).format('YYYY/MM/DD')}
                  </TextDate>
                </CalendarInfoContainer>
                <Title>
                  <a
                    href={`https://blog.alvinend.tech/${post.slug}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {post.title}
                  </a>
                </Title>
                <Desc>
                  {post.brief}
                </Desc>
                <ReadMoreText
                  href={`https://blog.alvinend.tech/${post.slug}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  Keep Reading
                </ReadMoreText>
              </Article>
          ))
        }
      </ContentContainer>

      <DotContainer>
        {renderDots()}
      </DotContainer>

      <ButtonContainer>
        <ButtonMore
          href={`https://blog.alvinend.tech`}
          target="_blank"
          rel="noreferrer"
        >
          See More
        </ButtonMore>
      </ButtonContainer>
    </OuterContainer>   
  )
}

export default BlogList