import React from 'react'
import SectionHeader from '../SectionHeader'
import styled from 'styled-components'
import { screen } from './screen'

const OuterContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;

  &  h1 {
    line-height: 1.4;
  }
`
const StyledDiv  = styled.div `
  width: 80%;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const ListContainer = styled.div`
  display: flex;
  margin-right: -100px;
  margin-top: 60px;
  width: 900px;
  flex-wrap: wrap;

  @media screen and (max-width: ${screen.mobile}) {
    width: 100%;
  }
`

const ListItem = styled.div`
  width: 200px;
  margin-right: 100px;
  margin-bottom: 50px;
`

const ImageFrame = styled.div`
  display: flex;
  align-items: center;
  width: 200px;
  height: 200px;
  margin-bottom: 30px;
  border-radius: 20px;
  overflow: hidden;
  box-shadow: 3px 5px 10px rgba(0,0,0,0.25);
`

const TextFrame = styled.div`
  text-align: center;

  & > h1 {
    font-weight: 800;
    font-size: 18px;
  }
`

const Works = ({ works }) => {
  return (
    <OuterContainer>
      <StyledDiv id="projects-section">
        <SectionHeader>My Latest Featured Project</SectionHeader>
        <p className="text-gray-600 text-center">
          Projects that I made with love and like to share it <br />
          (Psst, Click for more details at Github!)
        </p>

        <ListContainer>
          {works.map(work => (
            <ListItem>
              <ImageFrame onClick={() => { window.location.href = work.github }}>
                <img
                  alt={`Work ${work.title}`}
                  src={
                    !!work.image.childImageSharp
                      ? work.image.childImageSharp.fluid.src
                      : work.image
                  }
                />
              </ImageFrame>
              <TextFrame>
                <h1>{work.title}</h1>
                <p>{work.desc}</p>
              </TextFrame>
            </ListItem>
          ))}
        </ListContainer>
      </StyledDiv>
    </OuterContainer>
  )
}

export default Works
