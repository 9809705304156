import React from 'react'
import styled from 'styled-components'
import { Topbar } from './Topbar'
import { screen } from './screen'

const LeftContainer = styled.div`
  width: 50%;

  & > h1 {
    font-size: 60px;
    font-weight: 600;
    line-height: 1.2;
    margin-top: 20px;
    margin-bottom: 30px;
  }

  @media screen and (max-width: ${screen.mobile}) {
    width: 100%;
  }
`

const RightContainer = styled.div`
  width: 50%;
  height: 100%;
  background-image: url(${({ imageUrl }) => imageUrl});
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;

  @media screen and (max-width: ${screen.mobile}) {
    width: 100%;
    height: 600px;
    margin-top: 100px;
  }
`

const OuterContainer = styled.div`
  width: 100vw;
  display: flex;
  justify-content: center;
  margin-top: 80px;
`

const MainVisualContainer = styled.div`
  display: flex;
  align-items: center;
  width: 80%;
  max-width: 1400px;

  @media screen and (max-width: ${screen.mobile}) {
    flex-direction: column;
  }
`

const MainVisual = ({ image }) => {
  return (
    <div id="home-section">
      <Topbar />
      <OuterContainer>
        <MainVisualContainer>
          <LeftContainer>
            <p>Student</p>
            <h1>Endratno Alvin <br/> Full Stack <br/> Engineer</h1>
            <p>
              Hi there<span role="img" aria-label='wave'> 👋 </span> <br/>I'm Indonesian who love coffee and dogs!! Also code sometimes.<br/>
              Currently studying at Kyoto Institute of Information Science. <span role="img" aria-label='school'>🏫</span><br/>
              Also work as Engineer Intern <span role="img" aria-label='engineer'>👷‍♂️</span>
            </p>
            {/* <StyledButton>Contact Me</StyledButton> */}
          </LeftContainer>
          <RightContainer imageUrl={!!image.childImageSharp ? image.childImageSharp.fluid.src : image}>

          </RightContainer>
        </MainVisualContainer>
      </OuterContainer>
    </div>
  )
}

export default MainVisual
